<template>
    <div class="mainPart">
        <!-- 顶部 -->
        <div class="topAnalyse flex">
            <div class="flex flex-1">
                <div
                    class="topItem flex-between flex-column"
                    v-for="(item, index) in topList"
                    :key="index"
                >
                    <div class="flex">
                        <img
                            :src="item.src"
                            class="icon"
                            :style="{
                                width: item.srcWidth,
                                height: item.srcHeight,
                            }"
                        />
                        <div>
                            <div class="title">
                                {{ item.name }}
                            </div>
                            <div>
                                <span class="desc">{{ item.desc }}</span>
                                <span class="descNum">{{ item.descNum }}</span>
                                <span class="desc">个</span>
                            </div>
                            <div v-if="item.name == '监测数据'">
                                最新采集时间：{{ item.cjsj }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="descZb">
                            {{ item.descZb }}{{ item.zbNum }}%
                        </div>
                        <el-progress
                            :percentage="item.zbNum"
                            :format="formatProgress"
                            :color="item.color"
                            :text-inside="true"
                        ></el-progress>
                    </div>
                </div>
            </div>

            <el-button type="primary" class="btnPart" @click="goItem()"
                >工作台面</el-button
            >
        </div>
        <!-- 中间 -->
        <div class="centerPart">
            <div class="title">工作情况</div>
            <div class="flex">
                <div
                    class="centerItem flex-1 flex-between"
                    v-for="(item, index) in centerList"
                    :key="index"
                    :id="item.id"
                >
                    <NoMonitor v-if="!item.total" text="暂无数据"></NoMonitor>
                </div>

                <div class="line1"></div>
                <div class="line2"></div>
            </div>
        </div>
        <!-- 底部 -->
        <div class="bottomPart">
            <div class="title">监测数据统计</div>
            <div class="flex">
                <div class="echartPart">
                    <div class="echart" id="jcshtj"></div>
                    <NoMonitor v-if="!jcsjTotal" text="暂无统计"></NoMonitor>
                </div>

                <div class="flex-y-center flex-1">
                    <div
                        class="bottomItem"
                        v-for="(item, index) in jcsjtjList"
                        :key="index"
                    >
                        <div class="name">{{ item.name }}</div>
                        <div>
                            <div class="desc">未采集数据：{{ item.wcj }}</div>
                            <div class="desc">
                                待审核数据：{{ item.dshNum }}个
                            </div>
                        </div>
                        <div class="time">最新录入时间：{{ item.time }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
    components: {
        NoMonitor,
    },
    name: "",
    data() {
        return {
            topList: [
                {
                    name: "监测数据",
                    src: require("@image/lyj/icon/jcsj.png"),
                    srcWidth: "62px",
                    srcHeight: "55px",
                    desc: "待审核数据",
                    descNum: 0,
                    dw: "个",
                    descZb: "待审核占比",
                    zbNum: 0,
                    color: "#418cff",
                    cjsj: "",
                },
                {
                    name: "分析评估",
                    src: require("@image/lyj/icon/fxpg.png"),
                    srcWidth: "60px",
                    srcHeight: "53px",
                    desc: "待评估",
                    descNum: 0,
                    dw: "个",
                    descZb: "待评估占比",
                    zbNum: 0,
                    color: "#24d096",
                },
                {
                    name: "预警处置",
                    src: require("@image/lyj/icon/yjcz.png"),
                    srcWidth: "50px",
                    srcHeight: "59px",
                    desc: "待处置",
                    descNum: 0,
                    dw: "个",
                    descZb: "待处置占比",
                    zbNum: 0,
                    color: "#f34d4f",
                },
            ],
            centerList: [
                {
                    total: 0,
                    echart: null,
                    id: "left",
                    colorList: ["#ffcd5e", "#ff5599", "#4189ff", "#ffcd5e"],
                    title: "数据总数",
                    list: [
                        {
                            name: "分析评估",
                            value: 0,
                            per: 0,
                        },
                        {
                            name: "预警处置",
                            value: 0,
                            per: 0,
                        },
                        {
                            name: "监测总览",
                            value: 0,
                            per: 0,
                        },
                    ],
                },
                {
                    total: 0,
                    echart: null,
                    id: "center",
                    colorList: ["#71b603", "#F34D4E"],
                    title: "分析评估",
                    list: [
                        {
                            name: "已评估",
                            value: 0,
                            per: 0,
                        },
                        {
                            name: "未评估",
                            value: 0,
                            per: 0,
                        },
                    ],
                },
                {
                    total: 0,
                    echart: null,
                    id: "right",
                    colorList: ["#F21B1C", "#EE7B51"],
                    title: "预警处置",
                    list: [
                        {
                            name: "未处置",
                            value: 0,
                            per: 0,
                        },
                        {
                            name: "已处置",
                            value: 0,
                            per: 0,
                        },
                    ],
                },
            ],
            // 监测数据统计
            jcsjTotal: 0,
            jcsjtjList: [
                {
                    name: "消防制度",
                    wcj: "",
                    dshNum: 0,
                    time: "",
                },
                {
                    name: "机构管理",
                    wcj: "",
                    dshNum: 0,
                    time: "",
                },
                {
                    name: "林业项目",
                    wcj: "",
                    dshNum: 0,
                    time: "",
                },
            ],
            jcshtj: null,
        };
    },

    mounted() {
        this.getTopList();
        this.getCenterList();
        this.getJctjData();
    },
    computed: {
        ...mapGetters(["lyj_work_state"]),
    },
    methods: {
        ...mapMutations({
            SET_LYJ_GZTM: "SET_LYJ_GZTM",
        }),
        ...mapActions([
            "GetPersonnelDataStatistic",
            "GetWorkStatusStatistic",
            "GetMonitorDataStatisic",
        ]),
        // 获取个人数据统计
        async getTopList() {
            let result = await this.GetPersonnelDataStatistic({
                userid: localStorage.userId,
            });
            this.topList[0].zbNum = Number(result.DSHDATAPERCENT);
            this.topList[0].descNum = result.DSHDATACOUNT;
            this.topList[0].cjsj = result.LASTCJDATATIME;

            this.topList[1].zbNum = Number(result.DPGDATACOUNT);
            this.topList[1].descNum = result.DPGDATACOUNT;

            this.topList[2].zbNum = Number(result.DCZDATACOUNT);
            this.topList[2].descNum = result.DCZDATACOUNT;
        },
        // 获取 中间的数据
        async getCenterList() {
            let result = await this.GetWorkStatusStatistic({});
            this.centerList[0].total = result.WORKOVERVIEW.ALLDATACOUNT;
            this.centerList[0].list = [
                {
                    name: "分析评估",
                    value: result.WORKOVERVIEW.FXPGDATACOUNT,
                    per: result.WORKOVERVIEW.FXPGPERCENT,
                },
                {
                    name: "预警处置",
                    value: result.WORKOVERVIEW.YJCZDATACOUNT,
                    per: result.WORKOVERVIEW.YJCZPERCENT,
                },
                {
                    name: "监测总览",
                    value: result.WORKOVERVIEW.MONITORDATACOUNT,
                    per: result.WORKOVERVIEW.MONITORPERCENT,
                },
            ];
            this.centerList[1].total = result.FXPGSTATISIC.ALLDATACOUNT;
            this.centerList[1].list = [
                {
                    name: "已评估",
                    value: result.FXPGSTATISIC.YPGDATACOUNT,
                    per: result.FXPGSTATISIC.YPGPERCENT,
                },
                {
                    name: "未评估",
                    value: result.FXPGSTATISIC.WPGDATACOUNT,
                    per: result.FXPGSTATISIC.WPGPERCENT,
                },
            ];
            this.centerList[2].total = result.YJCZSTAISIC.ALLDATACOUNT;
            this.centerList[2].list = [
                {
                    name: "未处置",
                    value: result.YJCZSTAISIC.WCZDATACOUNT,
                    per: result.YJCZSTAISIC.WCZPERCENT,
                },
                {
                    name: "已处置",
                    value: result.YJCZSTAISIC.YCZDATACOUNT,
                    per: result.YJCZSTAISIC.YCZPERCENT,
                },
            ];
            this.centerList.map((item) => {
                this.initEchartCenterList(item);
            });
        },
        goItem() {
            this.SET_LYJ_GZTM({
                lyj_work_state: false,
            });
        },
        formatProgress() {
            return "";
        },
        // 中间的三个echart
        initEchartCenterList(item) {
            let echart = item.echart;
            let id = item.id;
            let title = item.title;
            let list = item.list;
            let colorList = item.colorList;
            let subtext = 0;
            item.list.map((item) => {
                subtext += item.value;
            });

            let option = {
                title: {
                    text: title,
                    subtext: subtext,
                    left: "center",
                    top: "42%",
                    textStyle: {
                        fontSize: 23,
                        color: "rgba(74,90,127,1)",
                    },
                    subtextStyle: {
                        fontSize: 23,
                        color: "rgba(74,90,127,1)",
                    },
                },
                grid: {
                    left: 0,
                    right: 0,
                },
                color: colorList,
                series: {
                    type: "pie",
                    hoverAnimation: false,
                    radius: [60, 80],
                    label: {
                        formatter: (e) => {
                            let str = `${e.data.name}\n${e.data.value}个${e.data.per}%`;
                            return str;
                        },
                        position: "outside", //显示在扇形上
                    },
                    labelLine: {
                        normal: {
                            length: 15,
                            length2: 80,
                            maxSurfaceAngle: 80,
                        },
                    },
                    data: list,
                },
            };
            this[echart] = this.$echarts.dispose(document.getElementById(id));
            if (subtext != 0) {
                this[echart] = this.$echarts.init(document.getElementById(id));
                this[echart].setOption(option);
            }
        },
        // 获取监测统计数据
        async getJctjData() {
            let result = await this.GetMonitorDataStatisic({});
            this.jcsjtjList = [
                {
                    name: "消防制度",
                    wcj: result[1].WCJSJDATE,
                    dshNum: result[1].DSHCOUNT,
                    time: result[1].LASTCJSJ,
                },
                {
                    name: "机构管理",
                    wcj: result[0].WCJSJDATE,
                    dshNum: result[0].DSHCOUNT,
                    time: result[0].LASTCJSJ,
                },
                {
                    name: "林业项目",
                    wcj: result[2].WCJSJDATE,
                    dshNum: result[2].DSHCOUNT,
                    time: result[2].LASTCJSJ,
                },
            ];
            this.jcsjTotal =
                result[0].DSHCOUNT + result[1].DSHCOUNT + result[2].DSHCOUNT;
            this.initEchartJcsjtj(result);
        },
        // 监测数据统计
        initEchartJcsjtj(list) {
            let xAxisData = [];
            let seriesData = [];
            list.map((item) => {
                xAxisData.push(item.JCXMC);
                seriesData.push(item.ALLCOUNT);
            });
            let option = {
                grid: {
                    top: 20,
                    bottom: 40,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                },
                xAxis: {
                    type: "category",
                    data: xAxisData,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(232,232,232,1)",
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: "rgba(163, 171, 180, 1)",
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    minInterval: 1,
                    axisLabel: {
                        textStyle: {
                            color: "rgba(163, 171, 180, 1)",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(232,232,232,1)",
                            width: 1,
                            type: "solid",
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(232,232,232,1)",
                        },
                    },
                },
                series: [
                    {
                        data: seriesData,
                        type: "bar",
                        barWidth: 20,
                        itemStyle: {
                            normal: {
                                barBorderRadius: 5,
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(96,197,255,1)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(66,138,245,1)",
                                        },
                                    ]
                                ),
                            },
                        },
                    },
                ],
            };
            this.jcshtj = this.$echarts.dispose(
                document.getElementById("jcshtj")
            );
            if (this.jcsjTotal) {
                this.jcshtj = this.$echarts.init(
                    document.getElementById("jcshtj")
                );
                this.jcshtj.setOption(option);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.mainPart {
    padding: 30px 27px;
    background: #eef5fd;
    height: 100%;
    .topAnalyse {
        .topItem {
            width: 26%;
            padding: 10px 20px;
            background-color: #fff;
            margin-right: 20px;
            box-shadow: 0 0 16px rgba(22, 115, 255, 0.1);
            .title {
                font-size: 24px;
                color: #4a5a7f;
            }
            .icon {
                margin-right: 20px;
            }
            .desc {
                font-size: 36px;
                color: #212b42;
            }
            .descNum {
                font-size: 36px;
                color: #f34f50;
            }
            .descZb {
                margin-top: 10px;
                font-size: 24px;
                color: #9aa2b5;
            }
        }
        .btnPart {
            height: 32px;
            min-width: 82px;
            line-height: 33px;
            text-align: center;
            padding: 0 10px;
            background-color: #05a28f;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
        }
    }
    .centerPart {
        width: 100%;
        margin-top: 10px;
        position: relative;
        background-color: #fff;
        padding: 0 10px;
        .title {
            font-size: 26px;
            color: rgba(35, 45, 80, 1);
            border-bottom: 1px solid #ecedf1;
            padding: 10px 0;
        }
        .centerItem {
            height: 240px;
            position: relative;
        }

        .line1 {
            position: absolute;
            left: 33%;
            top: 20%;
            width: 2px;
            height: 70%;
            background: rgba(22, 115, 255, 0.1);
            z-index: 1;
        }
        .line2 {
            position: absolute;
            right: 33%;
            top: 20%;
            width: 2px;
            height: 70%;
            background: rgba(22, 115, 255, 0.1);
            z-index: 1;
        }
    }
    .bottomPart {
        margin-top: 10px;
        position: relative;
        background-color: #fff;
        box-shadow: 0 0 16px rgba(22, 115, 255, 0.1);
        padding: 0 10px;
        .title {
            font-size: 26px;
            color: rgba(35, 45, 80, 1);
            border-bottom: 1px solid #ecedf1;
            padding: 10px 0;
        }
        .echartPart {
            width: 30%;
            height: 240px;
            position: relative;
            .echart {
                width: 100%;
                height: 1005;
            }
        }

        .bottomItem {
            width: 300px;
            height: 200px;
            box-shadow: 0 0 16px rgba(22, 115, 255, 0.1);
            padding: 0 10px;
            position: relative;
            margin-right: 10px;
            .name {
                font-size: 24px;
                color: rgba(51, 51, 51, 1);
                border-bottom: 1px solid rgba(236, 237, 241, 1);
                height: 56px;
                line-height: 56px;
            }
            .desc {
                margin-top: 10px;
                font-size: 18px;
                color: rgba(153, 153, 153, 1);
            }
            .time {
                font-size: 18px;
                color: rgba(153, 153, 153, 1);
                border-top: 1px solid rgba(236, 237, 241, 1);
                position: absolute;
                left: 10px;
                bottom: 10px;
            }
        }
    }
}
</style>
