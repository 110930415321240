<template>
    <div class="lyjPart warpComp flex-end">
        <div class="datePart common-element-date" v-if="dateRangePart.state">
            <el-date-picker
                v-model="dateRangePart.defaultValue"
                :style="{ width: dateRangePart.width }"
                type="daterange"
                :range-separator="dateRangePart.separator"
                :start-placeholder="dateRangePart.startPlaceholder"
                :end-placeholder="dateRangePart.endPlaceholder"
                :value-format="dateRangePart.valueFormat"
                :format="dateRangePart.valueFormat"
                @change="changeDateRange"
                popper-class="lyjSeLectTimeTab-date"
            >
            </el-date-picker>
        </div>
        <div class="datePart common-element-date" v-if="datePart.state">
            <el-date-picker
                :style="{ width: datePart.width }"
                :picker-options="datePart.option"
                v-model="datePart.defaultValue"
                align="right"
                :type="datePart.kind"
                clearable
                :editable="datePart.editable"
                :placeholder="datePart.placeholder"
                :value-format="datePart.valueFormat"
                :format="datePart.valueFormat"
                @change="changeDate"
                popper-class="lyjSeLectTimeTab-date"
            ></el-date-picker>
        </div>
        <div class="selectPart lyjSeLectTimeTab-select" v-if="selectPart.state">
            <el-select
                v-model="selectPart.defaultValue"
                :placeholder="selectPart.placeholder"
                :style="{ width: selectPart.width, height: '20px' }"
                popper-class="lyjSeLectTimeTab-popper"
                :clearable="selectPart.clearable"
                @change="changeSelect"
            >
                <el-option
                    v-for="(item, index) in selectPart.list"
                    :key="index"
                    :label="item[selectPart.label]"
                    :value="item[selectPart.value]"
                >
                </el-option>
            </el-select>
        </div>
        <!-- tab面板 -->
        <div class="tabPart flex" v-if="tabPart.list.length">
            <div
                v-for="(item, index) in tabPart.list"
                :key="index"
                class="tabItem pointer flex-both-center"
                :class="[
                    tabPart.activeNum === index ? 'tabActive' : 'tabDefault',
                ]"
                @click="changeTabItem(item, index)"
            >
                <span>{{ item[tabPart.value] }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    components: {},
    props: {
        dateRangeData: Object,
        dateData: Object,
        selectData: Object,
        tabData: Object,
    },
    data() {
        return {
            dateRangePart: {
                state: false,
                width: "248px",
                defaultValue: "", //默认值
                separator: "至",
                startPlaceholder: "选择日期",
                endPlaceholder: "选择日期",
                valueFormat: "yyyy-MM-dd",
            },
            datePart: {
                state: false,
                width: "148px",
                kind: "date",
                defaultValue: "", //默认值
                option: "",
                clearable: false,
                editable: true,
                placeholder: "选择日期",
                valueFormat: "yyyy-MM-dd",
                // format: "yyyy-MM-dd",
            },
            selectPart: {
                state: false,
                width: "100px",
                placeholder: "请选择",
                clearable: true,
                defaultValue: "", //默认值
                list: [],
                label: "",
                value: "",
            },
            tabPart: {
                activeNum: 0,
                list: [],
                value: "name",
            },
        };
    },
    computed: {},
    watch: {
        selectData: {
            immediate: true,
            handler() {
                $.extend(this.selectPart, this.selectData);
            },
            deep: true,
        },
    },
    created() {
        this.tabActiveNum = this.defaultIndex;
    },
    mounted() {
        $.extend(this.dateRangePart, this.dateRangeData);
        $.extend(this.datePart, this.dateData);
        $.extend(this.selectPart, this.selectData);
        $.extend(this.tabPart, this.tabData);
        // console.log("dateRangePart", this.dateRangePart);
        // console.log("datePart", this.datePart);
        // console.log("tabPart", this.tabPart);
        // console.log("selectPart", this.selectPart);
    },
    methods: {
        changeDateRange(item) {
            if (item) {
                this.$emit("getDateRange", item);
            } else {
                this.$emit("getDateRange", "");
            }
        },
        changeDate(item) {
            if (item) {
                this.$emit("getDateItem", item);
            } else {
                this.$emit("getDateItem", "");
            }
        },
        changeSelect(item) {
            if (item) {
                let obj = this.common.aryListFindOne(
                    this.selectPart.list,
                    this.selectPart.value,
                    item
                );
                this.$emit("getSelectItem", obj);
            } else {
                this.$emit("getSelectItem", null);
            }
        },
        // 单选框 点击
        changeTabItem(item, index) {
            this.tabPart.activeNum = index;
            this.$emit("getTabItem", item, index);
        },
        // 重置单选框
        resetItem(num) {
            this.tabPart.activeNum = num;
        },
    },
};
</script>
<style scoped lang="scss">
.warpComp {
    width: 100%;
    .datePart {
        margin-right: 15px;
    }
    .selectPart {
        margin-right: 15px;
    }
    .tabPart {
        .tabItem {
            min-width: 40px;
            padding: 0 4px;
            height: 20px;
            font-size: 14px;
            border-radius: 5px;
            margin-right: 15px;
            &:nth-last-child(1) {
                margin-right: 0;
            }
            color: #fff;
        }
        .tabDefault {
            background: rgba(68, 206, 246, 0.6);
        }

        .tabActive {
            background: rgba(68, 206, 246, 1);
        }
    }
}
</style>
