<template>
    <div class="lyxmEchart flex-between">
        <div class="lyxmItem">
            <div class="desc">
                项目总数{{ xmTotalNum }}个，经费总数{{ jfTotalNum }}万元
            </div>
            <div id="hyxmLeft" class="echartLeft"></div>
            <NoMonitor v-if="!leftTotalNum" text="暂无数据"></NoMonitor>
        </div>
        <div class="line"></div>
        <div class="lyxmItem">
            <div id="hyxmRight" class="echartRight"></div>
            <NoMonitor v-if="!rightTotalNum" text="暂无数据"></NoMonitor>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
import SeLectTimeTab from "../seLect_time_tab";

export default {
    components: { NoMonitor, SeLectTimeTab },
    name: "",
    props: {},
    data() {
        return {
            leftEchart: null,
            rightEchart: null,
            leftTotalNum: 0,
            rightTotalNum: 0,
            xmTotalNum: 0, //项目总数
            jfTotalNum: 0, //经费总数
        };
    },
    watch: {},
    mounted() {
        this.getLyxmData();
    },
    methods: {
        ...mapActions(["GetLyjBhgcStatisticInfo"]),
        // 林业项目
        async getLyxmData() {
            let result = await this.GetLyjBhgcStatisticInfo({});
            // result.RESULTVALUE = {
            //     LYJDATASTATISTICS: {
            //         COUNT: 10,
            //         JFZE: 0.0,
            //         JFLYINFOS: [
            //             {
            //                 JFLY: "中央财政经费",
            //                 JFZE: 10.0,
            //                 JFZB: "0.00%",
            //             },
            //             {
            //                 JFLY: "省财政经费",
            //                 JFZE: 20.0,
            //                 JFZB: "0.00%",
            //             },
            //             {
            //                 JFLY: "市财政经费",
            //                 JFZE: 30.0,
            //                 JFZB: "0.00%",
            //             },
            //             {
            //                 JFLY: "市以下财政经费",
            //                 JFZE: 40.0,
            //                 JFZB: "0.00%",
            //             },
            //             {
            //                 JFLY: "自筹经费",
            //                 JFZE: 50.0,
            //                 JFZB: "0.00%",
            //             },
            //         ],
            //     },
            //     LYXMSTATUS: [
            //         {
            //             CJSJ: "2017",
            //             XMCOUNT: "10",
            //             JFZE: "10",
            //         },
            //         {
            //             CJSJ: "2018",
            //             XMCOUNT: "0",
            //             JFZE: "0",
            //         },
            //         {
            //             CJSJ: "2019",
            //             XMCOUNT: "0",
            //             JFZE: "0",
            //         },
            //         {
            //             CJSJ: "2020",
            //             XMCOUNT: "0",
            //             JFZE: "0",
            //         },
            //         {
            //             CJSJ: "2021",
            //             XMCOUNT: "0",
            //             JFZE: "0",
            //         },
            //     ],
            // };
            this.xmTotalNum = result.RESULTVALUE.LYJDATASTATISTICS.COUNT; //项目总数
            this.jfTotalNum = result.RESULTVALUE.LYJDATASTATISTICS.JFZE; //经费总数
            result.RESULTVALUE.LYJDATASTATISTICS.JFLYINFOS.map((item) => {
                this.rightTotalNum += item.JFZE;
            });
            result.RESULTVALUE.LYXMSTATUS.map((item) => {
                this.leftTotalNum += Number(item.JFZE) + Number(item.XMCOUNT);
            });
            this.initEchartLyxmLeft(result.RESULTVALUE.LYXMSTATUS);
            this.initEchartLyxmRight(
                result.RESULTVALUE.LYJDATASTATISTICS.JFLYINFOS
            );
        },
        initEchartLyxmLeft(list) {
            let xAxisData = [];
            let seriesXmsl = [];
            let seriesJfze = [];
            list.map((item) => {
                xAxisData.push(item.CJSJ);
                seriesXmsl.push(item.XMCOUNT);
                seriesJfze.push(item.JFZE);
            });

            let option = {
                tooltip: {
                    trigger: "axis",
                    formatter(list) {
                        let str = ``;
                        list.map((item, inedx) => {
                            let dw = "个";
                            if (inedx == 1) {
                                dw = "万元";
                            }
                            str += `${item.marker}${item.seriesName} ${item.value} ${dw}<br>`;
                        });
                        return str;
                    },
                },
                legend: {
                    icon: "roundRect",
                    align: "right",
                    textStyle: {
                        color: "rgba(51, 51, 51, 1)",
                        fontSize: 14,
                        fontWeight: "bold",
                    },
                },
                grid: {
                    left: 30,
                    right: 30,
                    top: 50,
                    bottom: 10,
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: xAxisData,
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#8097B1",
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        minInterval: 1,
                        type: "value",
                        name: "数量（个）",
                        nameTextStyle: {
                            color: "",
                        },
                        axisLabel: {
                            fontSize: 14,
                            textStyle: {
                                color: "#666666",
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#8097B1"],
                                width: 1,
                                type: "dotted",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#8097B1",
                                width: 1,
                            },
                        },
                    },
                    {
                        type: "value",
                        name: "经费（万元）",
                        nameTextStyle: {
                            color: "",
                        },
                        axisLabel: {
                            fontSize: 14,
                            textStyle: {
                                color: "#666666",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: ["#8097B1"],
                                width: 1,
                                type: "dotted",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#8097B1",
                                width: 1,
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "项目数量",
                        type: "line",
                        hoverAnimation: false,
                        showAllSymbol: true,
                        symbol: "circle",
                        symbolSize: 5,
                        data: seriesXmsl,
                        lineStyle: {
                            color: "#4089FF",
                        },
                        itemStyle: {
                            color: "#4089FF",
                            borderColor: "#4089FF",
                            borderWidth: 2,
                        },
                        areaStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(64,137,255,0.3)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(64,137,255,0)",
                                        },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(105,196,254, 0.9)",
                                shadowBlur: 20,
                            },
                        },
                    },
                    {
                        name: "经费总额",
                        type: "line",
                        hoverAnimation: false,
                        data: seriesJfze,
                        yAxisIndex: 1,
                        showAllSymbol: true,
                        symbol: "circle",
                        symbolSize: 5,
                        lineStyle: {
                            color: "#70B603",
                        },
                        itemStyle: {
                            color: "#70B603",
                            borderColor: "#70B603",
                            borderWidth: 2,
                        },
                        areaStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(112,182,3,0.3)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(112,182,3,0)",
                                        },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(108,80,243, 0.9)",
                                shadowBlur: 20,
                            },
                        },
                    },
                ],
            };
            this.leftEchart = this.$echarts.dispose(
                document.getElementById("hyxmLeft")
            );
            if (this.leftTotalNum) {
                this.leftEchart = this.$echarts.init(
                    document.getElementById("hyxmLeft")
                );
                this.leftEchart.setOption(option);
            }
        },
        initEchartLyxmRight(list) {
            list.map((item) => {
                item.value = item.JFZE;
                item.name = item.JFLY;
            });
            let option = {
                title: {
                    text: this.rightTotalNum,
                    left: "center",
                    top: "26%",
                    textStyle: {
                        fontSize: 23,
                        color: "rgba(74,90,127,1)",
                    },
                },
                tooltip: {
                    formatter(item) {
                        let str = `${item.marker}${item.name}<br>${item.value}万元 (${item.percent}%)`;
                        return str;
                    },
                },
                legend: {
                    left: "center",
                    bottom: 10,
                    itemGap: 20,
                    padding: 10,
                    orient: "vertical",
                    itemWidth: 14,
                    itemHeight: 14,
                    textStyle: {
                        fontWeight: "bold",
                    },
                    data: [
                        "中央财政经费",
                        "省财政经费",
                        "市财政经费",
                        "\n",
                        "市以下财政经费",
                        "自筹经费",
                    ],
                    formatter(e) {
                        let str = ``;
                        switch (e) {
                            case "中央财政经费":
                                str = `${e} (${list[0].JFZB})`;
                                break;
                            case "省财政经费":
                                str = `${e} (${list[1].JFZB})`;
                                break;
                            case "市财政经费":
                                str = `${e} (${list[2].JFZB})`;
                                break;
                            case "市以下财政经费":
                                str = `${e} (${list[3].JFZB})`;
                                break;
                            case "自筹经费":
                                str = `${e} (${list[4].JFZB})`;
                                break;
                        }
                        return str;
                    },
                },
                series: [
                    {
                        name: "",
                        type: "pie",
                        center: ["50%", "30%"],
                        radius: ["40%", "50%"],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        label: {
                            show: false,
                            position: "center",
                        },
                        labelLine: {
                            show: false,
                        },
                        color: [
                            "#4089FF",
                            "#FF5599",
                            "#F33726",
                            "#54DB1F",
                            "#FECD5D",
                        ],
                        data: list,
                    },
                ],
            };
            this.rightEchart = this.$echarts.dispose(
                document.getElementById("hyxmRight")
            );
            if (this.rightTotalNum) {
                this.rightEchart = this.$echarts.init(
                    document.getElementById("hyxmRight")
                );
                this.rightEchart.setOption(option);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.lyxmEchart {
    width: 100%;
    height: 100%;
    position: relative;
    .lyxmItem {
        width: 50%;
        height: 100%;
        position: relative;
        .echartLeft {
            width: 100%;
            height: calc(100% - 40px);
        }
        .echartRight {
            width: 100%;
            height: 100%;
        }
        .desc {
            font-size: 16px;
            color: #333333;
            margin: 5px 0 0 5px;
            font-weight: bold;
        }
    }
    .line {
        width: 2px;
        height: calc(100% - 10px);
        background: #ecedf1;
    }
}
</style>