<template>
    <div class="bchjcEchart">
        <div class="desc">
            病虫害面积{{ bchjcPart.mj }}m²，占比{{ bchjcPart.zb }}%
        </div>
        <div class="desc">互联网自动虫情测报灯，遥感监测，现场调查</div>

        <div class="bchjcCtn">
            <div class="halfCircle"></div>
            <NoMonitor v-if="!bchjcPart.mj" text="暂无数据"></NoMonitor>
            <div id="bchjc" style="height: 100%"></div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
    components: { NoMonitor },
    name: "",
    props: {},
    data() {
        return {
            listAry: [],
            totalNum: 0,
            bchjc: null,
            // 病虫害监测
            bchjcPart: {
                mj: 0,
                zb: 0,
                echart: null,
            },
        };
    },
    watch: {},
    mounted() {
        this.getEchartData();
    },
    methods: {
        ...mapActions(["GetBchjcData"]),
        // 获取病虫害监测
        async getEchartData() {
            let result = await this.GetBchjcData({});
            this.bchjcPart.mj = result.MJ; //病虫害面积
            this.bchjcPart.zb = (result.MJ / result.ZMJ) * 100; //病虫害面积占比
            let leftnum = result.ZMJ - result.MJ;
            this.initEchartBchjc(result.MJ, leftnum);
        },
        initEchartBchjc(nowNum, leftnum) {
            let option = {
                title: [
                    {
                        text: `占比\n${this.bchjcPart.zb}%`,
                        y: "30%",
                        x: "center",
                        textAlign: "left",
                        textStyle: {
                            fontSize: 29,
                            color: "rgba(64,137,255,1)",
                        },
                    },
                ],
                series: [
                    {
                        type: "pie",
                        startAngle: 90,
                        radius: ["74%", "80%"],
                        center: ["50%", "50%"],
                        data: [
                            {
                                hoverOffset: 1,
                                value: nowNum,
                                name: "",
                                // label: {
                                //     show: false,
                                // },
                                labelLine: {
                                    normal: {
                                        smooth: true,
                                        lineStyle: {
                                            width: 0,
                                        },
                                    },
                                },
                                itemStyle: {
                                    color: "rgba(84,219,31,1)",
                                },
                                hoverAnimation: false,
                            },
                            {
                                label: {
                                    show: false,
                                },
                                labelLine: {
                                    normal: {
                                        smooth: true,
                                        lineStyle: {
                                            width: 0,
                                        },
                                    },
                                },
                                value: leftnum,
                                hoverAnimation: false,
                                itemStyle: {
                                    color: "rgba(251, 200, 79, 0)",
                                },
                            },
                        ],
                    },
                ],
            };
            this.bchjcPart.echart = this.$echarts.dispose(
                document.getElementById("bchjc")
            );
            this.bchjcPart.echart = this.$echarts.init(
                document.getElementById("bchjc")
            );
            this.bchjcPart.echart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
.bchjcEchart {
    width: 100%;
    height: 100%;
    position: relative;
    .desc {
        font-size: 16px;
        color: #232d50;
        font-weight: bold;
    }
    .bchjcCtn {
        width: 197px;
        height: 197px;
        margin: 0 auto;
        margin-top: 20px;
        position: relative;
        border-radius: 50%;
        background: rgba(168, 200, 255, 0.3);
        .halfCircle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            border: 4px solid rgba(168, 200, 255, 1);
            border-radius: 50%;
            clip-path: polygon(50% 0%, 100% 0%, 100% 3600%, 50% 50%);
        }
    }
}
</style>