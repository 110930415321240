<template>
    <div id="working_desk">
        <public-header class="public-header"></public-header>
        <div class="main-content flex">
            <left-menu
                v-if="system3ActiveNum != 1"
                class="left-menu"
                size="small"
                :menus="menus"
                :defaultIndex="defaultIndex"
                :mainMenuIndex="mainMenuIndex"
                @changeMenu="changeMenu"
            >
            </left-menu>
            <div class="flex-1">
                <router-view class="main"></router-view>
            </div>
        </div>
    </div>
</template>
<script>
import info_mixin from "./info_mixin";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    mixins: [info_mixin],
    name: "working_desk",
    components: {},
    computed: {
        ...mapGetters(["system", "system3ActiveNum"]),
    },
    mounted() {},
    methods: {
        ...mapMutations({}),
    },
};
</script>
<style lang="scss" scoped>
#working_desk {
    height: 100%;
    width: 100%;
    position: relative;
    .main-content {
        height: calc(100% - 80px);
        position: relative;
        .main {
            height: calc(100% - 30px);
            background-color: #f7f9f4;
        }
        .system3Part {
            width: 100%;
            height: 100%;
            background-color: #f7f9f4;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
        }
        .fxtjBtn {
            position: absolute;
            right: 120px;
            top: -47px;
            z-index: 2;
            height: 32px;
            min-width: 82px;
            line-height: 33px;
            text-align: center;
            padding: 0 10px;
            background-color: #05a28f;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
        }
    }
}
</style>
