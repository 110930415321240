<template>
    <div class="whjcEchart">
        <div class="typePart">
            <SeLectTimeTab
                :selectData="selectData"
                :tabData="tabData"
                @getTabItem="getTabItem"
            >
            </SeLectTimeTab>
        </div>

        <div id="whjc" class="echart"></div>
        <NoMonitor v-if="!totalNum" text="暂无数据"></NoMonitor>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
import SeLectTimeTab from "../seLect_time_tab";
export default {
    components: { NoMonitor, SeLectTimeTab },
    name: "",
    props: {},
    data() {
        return {
            listAry: [],
            totalNum: 0,
            whjc: null,
            tabId: "1",
            selectData: {
                state: false,
                width: "100px",
                defaultValue: "",
                clearable: false,
                list: [],
                label: "name",
                value: "name",
            },
            tabData: {
                list: [
                    {
                        id: "1",
                        name: "芒景-芒洪",
                    },
                    {
                        id: "2",
                        name: "大平掌",
                    },
                ],
                value: "name",
            },
        };
    },
    watch: {},
    mounted() {
        this.getEchartData();
    },
    methods: {
        ...mapActions(["GetWhjcTjt"]),
        // 年月切换
        getTabItem(item, index) {
            this.tabId = item.id;
            // if (index == 1) {
            //     this.selectData.state = true;
            //     if (this.selectData.list.length) {
            //         this.time = this.selectData.list[0].name;
            //         this.selectData.defaultValue = this.selectData.list[0].name;
            //     }
            // } else {
            //     this.selectData.state = false;
            // }
            this.getEchartData();
        },
        // 物候监测
        async getEchartData() {
            let result = await this.GetWhjcTjt({
                jcd: this.tabId,
            });
            console.log(
                "%c 🍖 物候监测: ",
                "font-size:20px;background-color: #3F7CFF;color:#fff;",
                result
            );
            if (!result.length) {
                this.totalNum = 0;
            }
            this.initEchart(result);
        },
        initEchart(list) {
            let seriesData = [];
            list.forEach((item) => {
                if (item.RQDTO) {
                    this.totalNum +=
                        Number(item.RQDTO.FYQ) +
                        Number(item.RQDTO.ZYQ) +
                        Number(item.RQDTO.KHQ) +
                        Number(item.RQDTO.GQ) +
                        Number(item.RQDTO.LYQ) +
                        Number(item.RQDTO.XMQ);
                    let obj = {
                        name: item.NF,
                        type: "line",
                        smooth: true,
                        showSymbol: true,
                        showAllSymbol: true,
                        symbol: "rect",
                        hoverAnimation: false,
                        symbolSize: 8,
                        lineStyle: {
                            width: 3,
                        },
                        data: [
                            [item.RQDTO.FYQ, "发芽期"],
                            [item.RQDTO.ZYQ, "展叶期"],
                            [item.RQDTO.KHQ, "开花期"],
                            [item.RQDTO.GQ, "果期"],
                            [item.RQDTO.LYQ, "落叶期"],
                            [item.RQDTO.XMQ, "休眠期"],
                        ],
                    };
                    seriesData.push(obj);
                }
            });
            let option = {
                grid: {
                    top: 35,
                    right: 40,
                    bottom: 30,
                    left: 50,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    formatter(list) {
                        let str = ``;
                        list.map((item) => {
                            let month = String(item.value[0]).split(".")[0];
                            let day = String(item.value[0]).split(".")[1];
                            str += ` ${item.value[1]} ${item.seriesName} 年 ${month} 月 ${day} 日 <br>`;
                        });
                        return str;
                    },
                },
                legend: {
                    show: true,
                    right: 0,
                    textStyle: {
                        color: "#C4D6E1",
                    },
                    icon: "rect",
                    itemWidth: 15,
                    itemHeight: 5,
                    itemGap: 37,
                },
                color: [
                    "rgba(140,84,255,1)",
                    "#5AD9A6",
                    "#5AC9D9",
                    "#5A5DD9",
                    "#7E5AD9",
                    "#D95A93",
                    "#D9AE5A",
                ],
                xAxis: [
                    {
                        name: "月",
                        nameTextStyle: {
                            color: "rgba(153,153,153,1)",
                        },
                        type: "value",
                        interval: 1,
                        min: 1,
                        max: 12.4,
                        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                        axisLabel: {
                            color: "rgba(153,153,153,1)",
                            fontSize: 16,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(153,153,153,1)",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(53,80,124,.5)",
                            },
                        },
                    },
                ],
                yAxis: {
                    type: "category",
                    axisLabel: {
                        color: "rgba(153,153,153,1)",
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(153,153,153,1)",
                        },
                    },
                    axisTick: {
                        show: true,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(53,80,124,.5)",
                        },
                    },
                    data: [
                        "发芽期",
                        "展叶期",
                        "开花期",
                        "果期",
                        "落叶期",
                        "休眠期",
                    ],
                },
                series: seriesData,
            };

            this.whjc = this.$echarts.dispose(document.getElementById("whjc"));
            if (this.totalNum) {
                this.whjc = this.$echarts.init(document.getElementById("whjc"));
                this.whjc.setOption(option);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.whjcEchart {
    width: 100%;
    height: 100%;
    position: relative;
    .echart {
        width: 100%;
        height: 100%;
    }
    .typePart {
        position: absolute;
        right: 0;
        top: -30px;
    }
}
</style>