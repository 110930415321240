<template>
    <div id="working_desk">
        <public-header class="public-header"></public-header>
        <div class="main-content flex">
            <left-menu
                v-if="lyjActiveNum != 2"
                class="left-menu"
                size="small"
                :menus="menus"
                :defaultIndex="defaultIndex"
                :mainMenuIndex="mainMenuIndex"
                @changeMenu="changeMenu"
            >
            </left-menu>
            <div class="flex-1">
                <router-view class="main"></router-view>
            </div>
            <div class="system2Part" v-if="lyj_work_state && lyjActiveNum == 0">
                <GztmTj></GztmTj>
            </div>
            <div class="system2Part" v-if="lyj_sjgl_state && lyjActiveNum == 2">
                <Sjgltj></Sjgltj>
            </div>
            <div class="fxtjBtn">
                <img
                    src="@image/lyj/icon/change.png"
                    title="工作统计"
                    @click="openGztmPanel()"
                    v-if="lyjActiveNum == 0"
                />

                <img
                    src="@image/lyj/icon/change.png"
                    title="数据统计"
                    @click="openSjglPanel()"
                    v-if="lyjActiveNum == 2"
                />
            </div>
        </div>
    </div>
</template>
<script>
import info_mixin from "./info_mixin";
import GztmTj from "@views/lyj/work_desk/index"; //工作统计
import Sjgltj from "@views/lyj/data_admin/index"; //数据统计

import { mapGetters, mapMutations } from "vuex";
export default {
    mixins: [info_mixin],
    name: "working_desk",
    components: {
        GztmTj,
        Sjgltj,
    },
    computed: {
        ...mapGetters([
            "system",
            "lyjActiveNum",
            "lyj_work_state",
            "lyj_sjgl_state",
        ]),
    },
    watch: {
        lyj_work_state: {
            immediate: true,
            handler() {
                console.log(
                    "%c 🍧 this.lyj_work_state: ",
                    "font-size:20px;background-color: #465975;color:#fff;",
                    this.lyj_work_state
                );
                console.log(
                    "%c 🍧 this.lyjActiveNum: ",
                    "font-size:20px;background-color: #465975;color:#fff;",
                    this.lyjActiveNum
                );
            },
        },
    },
    mounted() {
        this.menus[0].name = "数据维护";
        this.menus[0].routerName = "basicTable";
    },
    methods: {
        ...mapMutations({
            SET_LYJ_GZTM: "SET_LYJ_GZTM",
            SET_LYJ_SJGL: "SET_LYJ_SJGL",
        }),
        // 工作台面
        openGztmPanel() {
            this.SET_LYJ_GZTM({
                lyj_work_state: true,
            });
        },
        // 数据管理
        openSjglPanel() {
            this.SET_LYJ_SJGL({
                lyj_sjgl_state: true,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
#working_desk {
    height: 100%;
    width: 100%;
    position: relative;
    .main-content {
        height: calc(100% - 80px);
        position: relative;
        .main {
            height: calc(100% - 30px);
            background-color: #f7f9f4;
        }
        .system2Part {
            width: 100%;
            height: 100%;
            background-color: #f7f9f4;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
        }
        .fxtjBtn {
            position: absolute;
            right: 10px;
            top: 0px;
            cursor: pointer;
        }
    }
}
</style>
