<template>
    <div class="msmmEchart flex-between-y-center">
        <div class="echartPart">
            <div id="msmm" class="echart"></div>
            <img src="@image/lyj/icon/gsmm.png" class="img" />
            <NoMonitor v-if="!totalNum" text="暂无数据"></NoMonitor>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
    components: { NoMonitor },
    name: "",
    props: {},
    data() {
        return {
            listAry: [],
            totalNum: 0,
            msmm: null,
            colorList: [
                "#00007d",
                "#6b0000",
                "#007703",
                "#006a61",
                "#75006b",
                "#757604",
                "#ff6c00",
                "#c30000",
                "#00ab04",
                "#00a799",
                "#b100a2",
                "#b6b800",
                "#0024d9",
                "#ff0000",
                "#00db05",
                "#00cfbe",
                "#ff00e9",
                "#bba278",
                "#3855e4",
                "#ffbc8a",
                "#520a9c",
                "#00ffea",
                "#ff66f2",
                "#bc8c3a",
                "#558aff",
                "#ff6a6a",
                "#7aff7d",
                "#83fff5",
                "#ffb8f9",
                "#fec665",
                "#8bb4ff",
                "#ffc1c1",
                "#a2ffa4",
            ],
            lengendData: [],
        };
    },
    watch: {},
    mounted() {
        this.getEchartData();
    },
    methods: {
        ...mapActions(["GetGsmmKstj"]),
        // 植物多样性
        async getEchartData() {
            let result = await this.GetGsmmKstj({});
            if (result && result.length > 0) {
                this.listAry = result;
                this.listAry.map((item) => {
                    item.name = item.NAME;
                    item.value = item.SL;
                    this.totalNum += Number(item.SL);
                    this.lengendData.push(item.NAME);
                });
            }
            this.initEchart(this.listAry);
        },
        initEchart(list) {
            let option = {
                color: this.colorList,
                tooltip: {
                    trigger: "item",
                    formatter(e) {
                        let str = `${e.marker} ${e.name}<br> ${e.value} 棵  (${e.percent}%)`;
                        return str;
                    },
                },
                title: [
                    {
                        text: this.totalNum,
                        top: "center",
                        left: 98,
                        textStyle: {
                            fontSize: 32,
                            fontWeight: "bold",
                            color: "rgba(50,59,92,1)",
                        },
                    },
                ],
                legend: {
                    type: "scroll",
                    orient: "vertical",
                    right: 50,
                    icon: "roundRect",
                    align: "right",
                    textStyle: {
                        color: "rgba(51, 51, 51, 1)",
                        fontSize: 14,
                        fontWeight: "bold",
                    },
                },
                series: [
                    {
                        name: "环形",
                        type: "pie",
                        clockWise: false,
                        radius: ["32%", "36%"],
                        center: ["30%", "50%"],
                        hoverAnimation: false,
                        itemStyle: {
                            normal: {
                                color: "rgba(50,59,92,1)",
                                label: {
                                    show: false,
                                },
                                labelLine: {
                                    show: false,
                                },
                            },
                        },
                        data: [
                            {
                                name: "",
                                value: 1,
                            },
                        ],
                    },
                    {
                        type: "pie",
                        roseType: "radius",
                        radius: ["40%", "64%"],
                        center: ["30%", "50%"],
                        data: list,
                        hoverAnimation: false,
                        itemStyle: {
                            normal: {
                                borderColor: "#fff",
                                borderWidth: 1,
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        label: {
                            normal: {
                                show: false,
                            },
                        },
                    },
                ],
            };

            this.msmm = this.$echarts.dispose(document.getElementById("msmm"));
            if (this.totalNum) {
                this.msmm = this.$echarts.init(document.getElementById("msmm"));
                this.msmm.setOption(option);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.msmmEchart {
    position: relative;
    height: 100%;
    display: flex;
    .echartPart {
        width: 100%;
        height: 100%;
        position: relative;
        .echart {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 10;
        }
        .img {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            width: 226px;
            height: 206px;
        }
    }
}
</style>