<template>
    <div class="zwdyxEchart flex-between-y-center">
        <div class="echartPart">
            <div id="zwdyx" class="echart"></div>
            <NoMonitor v-if="!totalNum" text="暂无数据"></NoMonitor>
        </div>

        <div class="legendPart">
            <div
                class="legendItem"
                v-for="(item, index) in listAry"
                :key="index"
            >
                <i :style="{ backgroundColor: colorList[index] }"></i>
                <span class="title">{{ item.name }}</span>
                <div class="legendCtn">
                    <div class="name">主要物种</div>
                    <div class="value">{{ item.legend }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
    components: { NoMonitor },
    name: "",
    props: {},
    data() {
        return {
            listAry: [],
            totalNum: 0,
            zwdyx: null,
            colorList: ["#61FF00", "#FFE500", "#DB3844"],
            lengendData: [
                {
                    label: "乔木",
                    child: "类型1，类型2，类型3",
                },
                {
                    label: "灌木",
                    child: "类型1，类型2，类型3",
                },
                {
                    label: "草木",
                    child: "类型1，类型2，类型3",
                },
            ],
        };
    },
    watch: {},
    mounted() {
        this.getEchartData();
    },
    methods: {
        ...mapActions(["GetDyxTj"]),
        // 植物多样性
        async getEchartData() {
            let result = await this.GetDyxTj({});
            if (result && result.length > 0) {
                this.listAry = result;
                this.listAry.map((item) => {
                    item.name = item.ZWLX;
                    item.value = item.ZWSL;
                    item.legend = item.ZYWZ;
                    this.totalNum += Number(item.ZWSL);
                });
            }
            this.initEchart(this.listAry);
        },
        initEchart(list) {
            let option = {
                color: this.colorList,
                tooltip: {
                    trigger: "item",
                    formatter(e) {
                        let str = "";
                        if (e.seriesName != "环形") {
                            str = `${e.marker} ${e.name} <br> ${e.value}(${e.percent}%)`;
                        }
                        return str;
                    },
                },
                title: [
                    {
                        text: this.totalNum,
                        top: "center",
                        left: "center",
                        textStyle: {
                            fontSize: 32,
                            fontWeight: "bold",
                            color: "rgba(50,59,92,1)",
                        },
                    },
                ],
                series: [
                    {
                        name: "环形",
                        type: "pie",
                        clockWise: false,
                        center: ["50%", "50%"],
                        radius: ["54%", "60%"],
                        hoverAnimation: false,
                        itemStyle: {
                            normal: {
                                color: "rgba(50,59,92,1)",
                                label: {
                                    show: false,
                                },
                                labelLine: {
                                    show: false,
                                },
                            },
                        },
                        data: [
                            {
                                name: "",
                                value: 1,
                            },
                        ],
                    },
                    {
                        type: "pie",
                        radius: ["70%", "90%"],
                        center: ["50%", "50%"],
                        data: list,
                        hoverAnimation: false,
                        itemStyle: {
                            normal: {
                                borderColor: "#fff",
                                borderWidth: 2,
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        label: {
                            normal: {
                                show: false,
                            },
                        },
                    },
                ],
            };

            this.zwdyx = this.$echarts.dispose(
                document.getElementById("zwdyx")
            );
            if (this.totalNum) {
                this.zwdyx = this.$echarts.init(
                    document.getElementById("zwdyx")
                );
                this.zwdyx.setOption(option);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.zwdyxEchart {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    .echartPart {
        width: 226px;
        height: 206px;
        background: url("~@image/lyj/icon/zwdyx.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        .echart {
            width: 100%;
            height: 100%;
        }
    }
    .legendPart {
        margin-left: 20px;
        width: 50%;
        .legendItem {
            width: 174px;
            i {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: inline-block;
                margin: 0 9px;
            }
            span {
                color: #d7eaf5;
            }
            .title {
                font-size: 14px;
                color: #35507c;
            }
            .legendCtn {
                width: 174px;
                height: 54px;
                line-height: 22px;
                margin-top: 4px;
                padding: 4px 8px;
                border: 1px solid #bdd7ec;
                border-radius: 7px;
                font-size: 13px;
                color: #fff;
                background: rgba(64, 137, 255, 0.5);
            }
        }
    }
}
</style>