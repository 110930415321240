
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "@comp/left_menu.vue";
import PublicHeader from "@comp/public_header.vue";
export default {
    data() {
        return {
            systems: [],
            menus: [
                {
                    id: "1",
                    name: "监测数据",
                    routerName: "monitoringTable",
                    // bgPicture: "menu/desk-entry",
                    className: "icon-jianceshujucaiji-01",
                },
                {
                    id: "0",
                    name: "基础数据",
                    routerName: "basicTable",
                    // bgPicture: "menu/basic-data",
                    className: "icon-jichushujucaiji-01",
                },
                {
                    id: "1011",
                    name: "遥感监测",
                    routerName: "remote_sense",
                    // bgPicture: "menu/remote_sense",
                    className: "icon-yaogan",
                },
                {
                    id: "1005",
                    name: "分析评估",
                    routerName: "analysis-assess",
                    className: "icon-fenxipinggu-01",
                },
                {
                    id: "1007",
                    name: "预警处置",
                    routerName: "warning_dispose",
                    className: "icon-yujingchuzhi-01",
                },
                // {
                //     // id: "8001",
                //     id: "1008",
                //     name: "咨询专家",
                //     routerName: "consulting_expert",
                //     // bgPicture: "menu/zjzx_consulting_expert"
                //     className: "icon-zixunzhuanjia-01",
                // },
                {
                    // id: "8002",
                    id: "1009",
                    name: "专家分析",
                    routerName: "expert_analysis",
                    // bgPicture: "menu/zjzx_expert_analysis"
                    className: "icon-zhuanjiafenxi-01",
                },
                {
                    // id: "8003",
                    id: "1010",
                    name: "专家信息",
                    routerName: "expert_information",
                    // bgPicture: "menu/zjzx_expert_information"
                    className: "icon-zhuanjiaxinxi-01",
                },
            ],
            defaultIndex: 0,
            mainMenuIndex: "10",
        };
    },
    components: {
        LeftMenu,
        PublicHeader,
    },
    created() {
        if (localStorage.selectedId) {
            let routeName = this.$route.name;
            console.log('%c 🍥 routeName: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', routeName);
            switch (routeName) {
                case "monitorTable":
                    this.defaultIndex = 0;
                    break;
                case "basicTable":
                    this.defaultIndex = 1;
                    break;
                case "personaltask":
                    this.defaultIndex = 2;
                    break;
                case "taskstatistics":
                    this.defaultIndex = 3;
                    break;
                case "analysis-assess":
                    this.defaultIndex = 4;
                    break;
            }
        }
    },
    methods: {
        changeMenu(menu, index) {
            localStorage.removeItem("snSearch");
            localStorage.removeItem("snPage");
        }
    }
};