<template>
    <div class="mainPart">
        <div class="topPart flex-between">
            <div class="topLeft flex-between">
                <div class="topItem">
                    <div class="title">日常巡查</div>
                    <div class="echart">
                        <EchartRcxc></EchartRcxc>
                    </div>
                </div>

                <div class="topItem">
                    <div class="title">消防制度</div>
                    <div class="echart">
                        <EchartXfzd></EchartXfzd>
                    </div>
                </div>
            </div>

            <div class="topRight">
                <div class="title">林业项目</div>
                <div class="echart">
                    <EchartLyxm></EchartLyxm>
                </div>
            </div>

            <el-button type="primary" class="btnPart" @click="goItem()"
                >数据管理</el-button
            >
        </div>
        <!-- 底部 -->
        <div class="bottomPart">
            <div class="title">生态环境</div>
            <div class="flex-between bottomCtn">
                <div class="bottomItem">
                    <div class="name">病虫害监测</div>
                    <div class="echart">
                        <EchartBchjc></EchartBchjc>
                    </div>
                </div>
                <div class="line"></div>
                <div class="bottomItem">
                    <div class="name">植物多样性</div>
                    <div class="echart">
                        <EchartZwdyx></EchartZwdyx>
                    </div>
                </div>
                <div class="line"></div>
                <div class="bottomItem">
                    <div class="name">古树名木</div>
                    <div class="echart">
                        <EchartGsmm></EchartGsmm>
                    </div>
                </div>
                <div class="line"></div>
                <div class="bottomItem">
                    <div class="name">动物多样性</div>
                    <div class="echartLast">
                        <EchartDwdyx></EchartDwdyx>
                    </div>
                    <div class="name">物候监测</div>
                    <div class="echartLast">
                        <EchartWhjc></EchartWhjc>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";

import EchartRcxc from "./comp/top/EchartRcxc"; //日常巡查
import EchartXfzd from "./comp/top/EchartXfzd"; //消防制度
import EchartLyxm from "./comp/top/EchartLyxm"; //林业项目

import EchartBchjc from "./comp/bottom/EchartBchjc"; //病虫害监测
import EchartWhjc from "./comp/bottom/EchartWhjc"; //物候监测
import EchartZwdyx from "./comp/bottom/EchartZwdyx"; //植物多样性
import EchartGsmm from "./comp/bottom/EchartGsmm"; //古树名木
import EchartDwdyx from "./comp/bottom/EchartDwdyx"; //动物多样性

export default {
    components: {
        NoMonitor,
        EchartRcxc,
        EchartXfzd,
        EchartLyxm,
        EchartBchjc,
        EchartWhjc,
        EchartZwdyx,
        EchartGsmm,
        EchartDwdyx,
    },
    name: "",
    data() {
        return {};
    },

    mounted() {
        // this.getLyxmData();
    },
    methods: {
        ...mapMutations({
            SET_LYJ_SJGL: "SET_LYJ_SJGL",
        }),
        ...mapActions([]),
        goItem() {
            this.SET_LYJ_SJGL({
                lyj_sjgl_state: false,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.mainPart {
    padding: 30px 27px;
    background: #eef5fd;
    height: 100%;
    .topPart {
        width: 100%;
        height: 380px;
        position: relative;
        .topLeft {
            width: calc(50% - 5px);
            .topItem {
                position: relative;
                width: calc(50% - 5px);
                padding: 0 10px;
                background-color: #fff;
                .echart {
                    height: calc(100% - 50px);
                }
                .typePart {
                    position: absolute;
                    right: 10px;
                    top: 100px;
                    z-index: 1;
                    .typeItem {
                        height: 20px;
                        line-height: 20px;
                        font-size: 14px;
                        text-align: center;
                        border-radius: 5px;
                        margin-right: 10px;
                        padding: 0 10px;
                    }
                    .typeItemActive {
                        background: #ffcd80;
                        color: #040e1e;
                    }
                    .typeItemDefault {
                        background: #35507c;
                        color: #a8c8ff;
                    }
                }
                .title {
                    font-size: 26px;
                    color: rgba(35, 45, 80, 1);
                    border-bottom: 1px solid #ecedf1;
                    padding: 10px 0;
                }

                .echart {
                    height: calc(100% - 60px);
                }
            }
        }
        .topRight {
            width: calc(50% - 5px);
            background-color: #fff;
            padding: 0 10px;
            .title {
                font-size: 26px;
                color: rgba(35, 45, 80, 1);
                border-bottom: 1px solid #ecedf1;
                padding: 10px 0;
            }
            .echart {
                height: calc(100% - 60px);
            }
        }
        .btnPart {
            height: 32px;
            min-width: 82px;
            line-height: 33px;
            text-align: center;
            padding: 0 10px;
            background-color: #05a28f;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    .bottomPart {
        height: 424px;
        margin-top: 10px;
        position: relative;
        background-color: #fff;
        box-shadow: 0 0 16px rgba(22, 115, 255, 0.1);
        padding: 0 10px;
        .title {
            font-size: 26px;
            color: rgba(35, 45, 80, 1);
            border-bottom: 1px solid #ecedf1;
            padding: 10px 0;
        }
        .bottomCtn {
            width: 100%;
            height: calc(100% - 60px);
            .bottomItem {
                width: calc(25% - 5px);
                height: 100%;
                padding: 0 10px;
                position: relative;
                .name {
                    font-size: 16px;
                    color: #232d50;
                    height: 50px;
                    line-height: 50px;
                    font-weight: bold;
                }
                .echart {
                    height: calc(100% - 50px);
                }
                .echartLast {
                    width: 100%;
                    height: calc(50% - 50px);
                }
            }
            .line {
                width: 2px;
                height: calc(100% - 10px);
                background: #ecedf1;
            }
        }
    }
}
</style>
