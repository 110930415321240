<template>
    <div class="rxcxEchart">
        <div class="desc">
            总巡查次数{{ rcxcPart.totalNum }}次，异常数据{{
                rcxcPart.ycNum
            }}条，未解除{{ rcxcPart.wjcNum }}条
        </div>
        <div class="typePart">
            <SeLectTimeTab
                :selectData="rcxcPart.selectData"
                :tabData="rcxcPart.tabData"
                @getTabItem="getRcxcTabItem"
                @getSelectItem="getRcxcSelectItem"
            >
            </SeLectTimeTab>
        </div>
        <div id="rcxcEchart" class="echart"></div>
        <!-- <NoMonitor v-if="!bchjcPart.mj" text="暂无数据"></NoMonitor> -->
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
import SeLectTimeTab from "../seLect_time_tab";

export default {
    components: { NoMonitor, SeLectTimeTab },
    name: "",
    props: {},
    data() {
        return {
            // 日常巡查
            rcxcPart: {
                totalNum: 0,
                type: 1, // 0:年 1:月
                tabData: {
                    activeNum: 1,
                    list: [
                        {
                            name: "年",
                        },
                        {
                            name: "月",
                        },
                    ],
                    value: "name",
                },
                selectData: {
                    state: false,
                    width: "100px",
                    defaultValue: "",
                    clearable: false,
                    list: [],
                    label: "name",
                    value: "name",
                },
                time: "",
                totalNum: 0,
                ycNum: 0,
                wjcNum: 0,
                echart: null,
            },
        };
    },
    watch: {},
    mounted() {
        this.getRcxcYearData();
    },
    methods: {
        ...mapActions(["GetRcxcYearList", "GetRcxcStatisticInfo"]),
        // 日常巡查 年份
        async getRcxcYearData() {
            this.rcxcPart.selectData.list;
            let result = await this.GetRcxcYearList({});
            result.RESULTVALUE.map((item) => {
                let obj = {
                    name: item,
                };
                this.rcxcPart.selectData.list.push(obj);
            });
            if (this.rcxcPart.selectData.list.length) {
                this.rcxcPart.selectData.defaultValue = result.RESULTVALUE[0];
                this.rcxcPart.time = result.RESULTVALUE[0];
            } else {
                this.rcxcPart.type = 0;
                this.rcxcPart.tabData.activeNum = 0;
            }
            this.getRcxcData();
        },
        // 日常巡查
        async getRcxcData() {
            let result = await this.GetRcxcStatisticInfo({
                lx: this.rcxcPart.type, //0:年 1:月
                time: this.rcxcPart.type == 1 ? this.rcxcPart.time : "",
            });
            this.rcxcPart.totalNum = result.RESULTVALUE.XCCOUNT;
            this.rcxcPart.ycNum = result.RESULTVALUE.YCCOUNT;
            this.rcxcPart.wjcNum = result.RESULTVALUE.WJCCOUNT;
            this.initEchartRcxc(result.RESULTVALUE.XCQKSTATUS);
        },
        // 年月切换
        getRcxcTabItem(item, index) {
            this.rcxcPart.type = index;
            if (index == 1) {
                this.rcxcPart.selectData.state = true;
                if (this.rcxcPart.selectData.list.length) {
                    this.rcxcPart.time = this.rcxcPart.selectData.list[0].name;
                    this.rcxcPart.selectData.defaultValue = this.rcxcPart.selectData.list[0].name;
                }
            } else {
                this.rcxcPart.selectData.state = false;
            }
            this.getRcxcData();
        },
        // 选择时间
        getRcxcSelectItem(item) {
            this.rcxcPart.time = item.name;
            this.getRcxcData();
        },
        initEchartRcxc(list) {
            let xAxisData = [];
            let seriesDataXcNum = [];
            let seriesDataYcNum = [];
            list.map((item) => {
                xAxisData.push(item.CJSJ);
                seriesDataXcNum.push(item.XCCS);
                seriesDataYcNum.push(item.YCSL);
            });

            let option = {
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    left: 0,
                    data: ["巡查次数", "异常次数"],
                    icon: "roundRect",
                    align: "right",
                    textStyle: {
                        color: "rgba(51, 51, 51, 1)",
                        fontSize: 14,
                        fontWeight: "bold",
                    },
                },
                grid: {
                    left: 20,
                    right: 20,
                    top: 50,
                    bottom: 50,
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: xAxisData,
                        splitLine: {
                            show: true,
                            // lineStyle: {
                            //     color: ["#8097B1"],
                            //     width: 1,
                            //     type: "solid",
                            // },
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#8097B1",
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        minInterval: 1,
                        type: "value",
                        name: "数量（次）",
                        nameTextStyle: {
                            color: "",
                        },
                        axisLabel: {
                            fontSize: 14,
                            textStyle: {
                                color: "#666666",
                            },
                        },
                        splitLine: {
                            show: true,
                            // lineStyle: {
                            //     color: ["#8097B1"],
                            //     width: 1,
                            //     type: "solid",
                            // },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#8097B1",
                                width: 1,
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "巡查次数",
                        type: "line",
                        hoverAnimation: false,
                        showAllSymbol: true,
                        symbol: "circle",
                        symbolSize: 5,
                        data: seriesDataXcNum,
                        lineStyle: {
                            color: "#2E5BFF",
                        },
                        itemStyle: {
                            color: "#2E5BFF",
                            borderColor: "#2E5BFF",
                            borderWidth: 2,
                        },
                        areaStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(46,91,255,0.3)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(46,91,255,0)",
                                        },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(105,196,254, 0.9)",
                                shadowBlur: 20,
                            },
                        },
                    },
                    {
                        name: "异常次数",
                        type: "line",
                        hoverAnimation: false,
                        data: seriesDataYcNum,
                        showAllSymbol: true,
                        symbol: "circle",
                        symbolSize: 5,
                        lineStyle: {
                            color: "#8C54FF",
                        },
                        itemStyle: {
                            color: "#8C54FF",
                            borderColor: "#8C54FF",
                            borderWidth: 2,
                        },
                        areaStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(140, 84, 255, 0.3)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(140,84,255,0)",
                                        },
                                    ],
                                    false
                                ),
                                shadowColor: "rgba(108,80,243, 0.9)",
                                shadowBlur: 20,
                            },
                        },
                    },
                ],
            };
            this.gzqk = this.$echarts.dispose(
                document.getElementById("rcxcEchart")
            );
            this.gzqk = this.$echarts.init(
                document.getElementById("rcxcEchart")
            );
            this.gzqk.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
.rxcxEchart {
    width: 100%;
    height: 100%;
    position: relative;

    .desc {
        font-size: 16px;
        color: #333333;
        margin: 5px 0 0 5px;
        font-weight: bold;
    }
    .echart {
        width: 100%;
        height: 100%;
    }
}
</style>