<template>
    <div class="xfzdEchart">
        <div id="xfzd" class="echart"></div>
        <NoMonitor v-if="!xfzdPart.totalNum" text="暂无数据"></NoMonitor>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
import SeLectTimeTab from "../seLect_time_tab";

export default {
    components: { NoMonitor, SeLectTimeTab },
    name: "",
    props: {},
    data() {
        return {
            // 消防制度
            xfzdPart: {
                echart: null,
                totalNum: 0,
            },
        };
    },
    watch: {},
    mounted() {
        this.getXfzdData();
    },
    methods: {
        ...mapActions(["GetAxfStatisticInfo"]),
        // 消防制度
        async getXfzdData() {
            let result = await this.GetAxfStatisticInfo({});
            // result.RESULTVALUE.SXCOUNT = 15;
            // result.RESULTVALUE.FZCOUNT = 105;
            this.xfzdPart.totalNum =
                result.RESULTVALUE.SXCOUNT + result.RESULTVALUE.FZCOUNT;
            let list = [
                {
                    name: "生效",
                    value: result.RESULTVALUE.SXCOUNT,
                    percent: Number(result.RESULTVALUE.SXPERCENT.split("%")[0]),
                },
                {
                    name: "废止",
                    value: result.RESULTVALUE.FZCOUNT,
                    percent: Number(result.RESULTVALUE.FZPERCENT.split("%")[0]),
                },
            ];
            this.initEchartXfzd(list);
        },
        // 消防制度Echart
        initEchartXfzd(list) {
            let colorList = ["#4089FF", "#FF5599"];
            let subtext = 0;
            let seriesData = list;
            list.map((item) => {
                subtext += item.value;
            });
            let option = {
                title: {
                    text: "制度总数",
                    subtext: subtext,
                    left: "28%",
                    top: "42%",
                    textAlign: "center",
                    textStyle: {
                        fontSize: 23,
                        color: "rgba(74,90,127,1)",
                    },
                    subtextStyle: {
                        fontSize: 23,
                        color: "rgba(74,90,127,1)",
                        align: "center",
                    },
                },
                legend: {
                    right: 50,
                    y: "center",
                    orient: "vertical",
                    itemWidth: 14,
                    itemHeight: 14,
                    textStyle: {
                        fontWeight: "bold",
                    },
                    formatter(e) {
                        let str = ``;
                        if (e == "生效") {
                            str = `生效 ${list[0].value}个 (${list[0].percent}%)`;
                        } else {
                            str = `废止 ${list[1].value}个 (${list[1].percent}%)`;
                        }
                        return str;
                    },
                },
                tooltip: {
                    formatter(e) {
                        let str = `${e.marker} ${e.name} <br>${e.value}个 (${e.percent}%)`;
                        return str;
                    },
                },
                grid: {
                    left: 100,
                    right: 100,
                },
                color: colorList,
                series: {
                    type: "pie",
                    hoverAnimation: false,
                    center: ["30%", "50%"],
                    radius: [80, 100],
                    label: {
                        show: false,
                        formatter: (e) => {
                            let str = `${e.data.name}\n${e.data.value}个${e.percent}%`;
                            return str;
                        },
                        position: "outside", //显示在扇形上
                    },
                    labelLine: {
                        normal: {
                            length: 15,
                            length2: 80,
                            maxSurfaceAngle: 80,
                        },
                    },
                    data: seriesData,
                },
            };
            this.xfzdPart.echart = this.$echarts.dispose(
                document.getElementById("xfzd")
            );
            if (subtext != 0) {
                this.xfzdPart.echart = this.$echarts.init(
                    document.getElementById("xfzd")
                );
                this.xfzdPart.echart.setOption(option);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.xfzdEchart {
    width: 100%;
    height: 100%;
    position: relative;

    .desc {
        font-size: 16px;
        color: #333333;
        margin: 5px 0 0 5px;
        font-weight: bold;
    }
    .echart {
        width: 100%;
        height: 100%;
    }
}
</style>