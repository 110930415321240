<template>
    <div class="dwdyxEchart">
        <div class="typePart">
            <SeLectTimeTab
                :selectData="selectData"
                :tabData="tabData"
                @getTabItem="getTabItem"
                @getSelectItem="getSelectItem"
            >
            </SeLectTimeTab>
        </div>
        <div id="dwdyx" class="echart"></div>
        <NoMonitor v-if="!totalNum" text="暂无数据"></NoMonitor>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
import SeLectTimeTab from "../seLect_time_tab";
export default {
    components: { NoMonitor, SeLectTimeTab },
    name: "",
    props: {},
    data() {
        return {
            listAry: [],
            totalNum: 0,
            dwdyx: null,
            year: "",
            selectData: {
                state: true,
                width: "100px",
                defaultValue: "",
                clearable: false,
                list: [],
                label: "name",
                value: "name",
            },
            tabData: {
                list: [
                    // {
                    //     id: "1",
                    //     name: "芒景-芒洪",
                    // },
                    // {
                    //     id: "2",
                    //     name: "大平掌",
                    // },
                ],
                value: "name",
            },
        };
    },
    watch: {},
    mounted() {
        this.getYearList();
    },
    methods: {
        ...mapActions(["GetDwdyxTj", "GetNyList"]),
        // 获取时间 年份
        async getYearList() {
            let result = await this.GetNyList({
                sblx: "351ee3bb-9c3c-473b-a9e4-2a6218749a97",
            });
            result.RESULTVALUE.map((item) => {
                let obj = {
                    name: item,
                };
                this.selectData.list.push(obj);
            });
            if (this.selectData.list.length) {
                this.selectData.defaultValue = this.selectData.list[0].name;
                this.year = this.selectData.list[0].name;
                this.getEchartData();
            }
        },
        // 年月切换
        getTabItem(item, index) {
            // this.tabId = item.id;
            // if (index == 1) {
            //     this.selectData.state = true;
            //     if (this.selectData.list.length) {
            //         this.year = this.selectData.list[0].name;
            //         this.selectData.defaultValue = this.selectData.list[0].name;
            //     }
            // } else {
            //     this.selectData.state = false;
            // }
            // this.getEchartData();
        },
        // 选择时间
        getSelectItem(item) {
            this.year = item.name;
            this.getEchartData();
        },
        // 植物多样性
        async getEchartData() {
            let result = await this.GetDwdyxTj({
                nf: this.year,
            });
            if (result && result.length > 0) {
                this.listAry = result;
                this.listAry.map((item) => {
                    item.name = item.WZLX;
                    item.value = item.SL;
                    this.totalNum += Number(item.SL);
                });
            }
            this.initEchart(this.listAry);
        },
        initEchart(list) {
            let xAxisData = [];
            let seriesData = [];
            list.map((item) => {
                xAxisData.push(item.name);
                seriesData.push(item.value);
            });

            let option = {
                tooltip: {
                    trigger: "item",
                    formatter(e) {
                        let str = `${e.name}<br> ${e.value} 个 `;
                        return str;
                    },
                },
                grid: {
                    left: 10,
                    right: 10,
                    top: 30,
                    bottom: 10,
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: xAxisData,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(153,153,153,1)",
                            width: 1,
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    name: "个",
                    minInterval: 1,
                    nameTextStyle: {
                        color: "rgba(153,153,153,1)",
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(153,153,153,1)",
                            width: 1,
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(153,153,153,1)",
                        },
                    },
                },
                series: [
                    {
                        data: seriesData,
                        type: "bar",
                        barWidth: "10",
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        //渐变色----------------
                                        color: "rgba(124,250,207,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(37,208,150,1)",
                                    },
                                ],
                                false
                            ),
                        },
                    },
                ],
            };

            this.dwdyx = this.$echarts.dispose(
                document.getElementById("dwdyx")
            );
            if (this.totalNum) {
                this.dwdyx = this.$echarts.init(
                    document.getElementById("dwdyx")
                );
                this.dwdyx.setOption(option);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.dwdyxEchart {
    width: 100%;
    height: 100%;
    position: relative;
    .echart {
        width: 100%;
        height: 100%;
    }
    .typePart {
        position: absolute;
        right: 0;
        top: -30px;
    }
}
</style>